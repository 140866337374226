import clsx from "clsx"
import React from "react"

type SelectProps = {
  plain?: boolean
  containerClassName?: string
  error?: string | null
} & React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>

const SelectRender: React.ForwardRefRenderFunction<
  HTMLSelectElement,
  SelectProps
> = (
  {plain, containerClassName, error, className, children, ...props},
  ref,
) => {
  const classes = clsx(
    "py-[10px] pr-[50px] pl-[18px] bg-[right_10px_center] text-steel rounded border-[1.5px]",
    className,
    error
      ? "invalid:text-solarflare border-solarflare focus:border-solarflare focus:ring-solarflare"
      : plain
      ? "invalid:text-ash border-ash focus:border-steel focus:ring-steel"
      : "border-ash hover:border-smoke active:border-steel focus:active:border-steel shadow-[1px_1px_6px_0px_rgba(0,0,0,0.1)]",
  )
  return (
    <div className={containerClassName}>
      <select className={classes} {...props} ref={ref}>
        {children}
      </select>
      {!!error && (
        <span className="text-solarflare mt-[7px] ml-1">{error}</span>
      )}
    </div>
  )
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  SelectRender,
)
